<template>
 
  <div v-if="currentProduct" class="container px-5 mx-auto my-16 md:px-8">
    <div class="mx-auto md:w-3/4">
      <img
        loading="lazy"
        alt=""
        :src="URL + currentProduct.image"
        class="object-cover w-full h-96"
      />
      <span class="block w-full h-3 my-5 bg-primary"></span>
      <div class="p-3">
        <h4 class="text-5xl uppercase text-dark">
          {{ locale == 'ar' ? currentProduct.name_ar : currentProduct.name_en }}
        </h4>
        <p class="mt-4 text-lg text-gray-600">
          {{
            locale == 'ar' ? currentProduct.description_ar : currentProduct.description_en
          }}
        </p>
      </div>
    </div>
    <div
      v-if="productItems && productItems.length > 0"
      class="grid grid-cols-2 gap-5 my-12 md:grid-cols-3 lg:grid-cols-4 md:gap-8 lg:gap-12"
    >
      <div
        v-for="product_item in productItems"
        :key="product_item.id"
        class="overflow-hidden transition-all duration-300 bg-white border border-gray-100 rounded-2xl shadow-primary hover:shadow-md hover:bg-gray-50"
      >
        <img
          :src="URL + product_item.image"
          alt=""
          loading="lazy"
          class="object-cover w-full h-36 md:h-44 md:object-contain"
        />
        <div class="p-3 md:p-5">
          <h4 class="text-xl font-medium md:text-2xl text-dark">
            {{ locale == 'ar' ? product_item.name_ar : product_item.name_en }}
          </h4>
          <span class="block w-full h-1 my-2 rounded-md bg-primary md:my-3"></span>
          <p class="my-2 text-base text-black line-clamp md:text-lg md:my-3">
            {{
              locale == 'ar' ? product_item.description_ar : product_item.description_en
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'product',
  data() {
    return {
      // URL: process.env.VUE_APP_URL,
      URL: 'https://api.nowarat.ly/',
    }
  },
  computed: {
    products() {
      return this.$store.getters.products
    },
    locale() {
      return this.$i18n.locale
    },
    currentProduct() {
      return (
        this.products &&
        this.products.find((prod) => prod.product_id == this.$route.params.product_id)
      )
    },
    productItems() {
      return (
        this.$store.getters.item_product &&
        this.$store.getters.item_product.filter(
          (prod) => prod.product_id == this.$route.params.product_id
        )
      )
    },
  },
}
</script>
<style scoped>
.product-bg {
  background-image: url(/single-product.jpg);
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.overloay {
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.4);
}
</style>
